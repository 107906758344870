import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Textbox from '../components/Textbox';
import Ball from '../components/beach/Ball';
import Books from '../components/beach/Books';
import Olivia from '../components/beach/Olivia';
import ArrowButton from '../components/ArrowButton';

export default function BeachBall() {
    return (
        <Wrapper>
            <Text>
                <p>I see you picked up that beach ball. What are you going to do with it?</p>

                <Textbox />
                <Link to="/beach-ball-1">
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Text>
            <Books link="beach-books" />
            <Ball link="beach-ball" />
            <Olivia link="beach-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/beach-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
