import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Ball(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/beach-ball.png" alt="apple" />
            </Link>
        </>
    );
}

const Image = styled.img`
    left: 6%;
    position: absolute;
    bottom: 9%;
    width: 92px;
    z-index: 1;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        width: 107px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        width: 123px;
    }
    @media screen and (min-width: 390px) {
        width: 123px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        bottom: 13%;
        left: 12%;
        width: 162px;
    }
`;
