import React from 'react';
import styled from 'styled-components';
import Topmenu from '../components/Topmenu';
import { Link } from 'react-router-dom';

export default function Invitation(props) {
    return (
        <Wrapper>
            <Topmenu />
            <Info>
                <ul>
                    <Date>
                        <strong>Date</strong> <span>Saturday, Sept. 5</span>
                    </Date>
                    <Place>
                        <strong>Place</strong>{' '}
                        <span>Olivia's house!</span>
                    </Place>
                    <Time>
                        <strong>Time</strong>
                        <span>
                            Friends: 11am
                            <br />
                            Family: 4pm
                            <br />
                            <Link to="/zoom">Zoom: 6pm</Link>
                            <br />
                        </span>
                    </Time>
                </ul>
            </Info>
            <Wishlist>
                <a
                    href="https://www.amazon.com/hz/wishlist/ls/3VE3XEHJJO9GM?ref_=wl_share"
                    target="new"
                >
                    <h3>Olivia's Amazon wishlist</h3>
                </a>
            </Wishlist>
            <House>
                <img src="/images/house.png" alt="house" />
            </House>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-color: #b9f1fe;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
`;

const House = styled.div`
    bottom: 0px;
    position: absolute;
    text-align: center;
    img {
        display: block;
        margin: 0 auto;
        width: 60%;
        @media screen and (min-width: 760px) {
            width: 400px;
        }
    }
`;

const Info = styled.div`
    color: #222;
    margin: 0px auto 15px auto;
    padding: 95px 30px 0 20px;
    ul {
        list-style-type: none;
    }

    strong {
        background-color: #fd7926;
        border-radius: 30px;
        color: black;
        display: inline-block;
        padding: 2px 10px;
        text-align: center;
        width: 60px;
    }
    @media screen and (min-width: 375px) {
        margin-bottom: 25px;
    }
    @media screen and (min-height: 800px) {
        margin: 0px auto 35px auto;
    }
    @media screen and (min-width: 760px) {
        font-size: 21px;
        padding: 125px 50px 0 50px;
        strong {
            width: 80px;
        }
    }
`;

const Date = styled.li`
    margin-bottom: 15px;
    display: block;

    span {
        display: inline-block;
        margin-left: 15px;
        vertical-align: top;
        width: 145px;
    }
`;

const Time = styled.li`
    margin-bottom: 15px;
    display: inline-block;

    span {
        display: inline-block;
        margin-left: 15px;
        vertical-align: top;
        width: 140px;
    }
`;

const Place = styled.li`
    margin-bottom: 15px;
    display: block;
    span {
        display: inline-block;
        margin-left: 15px;
        vertical-align: top;
        width: 170px;
        @media screen and (min-width: 360px) {
            width: 190px;
        }
    }
`;

const Wishlist = styled.div`
    background-color: white;
    border-radius: 20px;
    border: 1px solid #fd7926;
    margin-left: 20px;
    padding: 7px 18px;
    width: fit-content;
    a {
        text-decoration: none;
        color: #222;
    }
    @media screen and (min-width: 760px) {
        font-size: 26px;
    }
`;
