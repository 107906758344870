import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TiHome } from 'react-icons/ti';

export default function Options() {
    return (
        <Wrapper>
            <Home>
                <Link to="/home">
                    <TiHome />
                </Link>
                <h3>Choose a location</h3>
            </Home>

            <Link to="/town">
                <Town>
                    <img src="/images/town-tile.jpg" alt="town" />
                </Town>
            </Link>

            <Link to="/fishing">
                <Fishing>
                    <img src="/images/fishing-tile.jpg" alt="town" />
                </Fishing>
            </Link>

            <Link to="/beach">
                <Beach>
                    <img src="/images/beach-tile.jpg" alt="town" />
                </Beach>
            </Link>

            <Link to="/night">
                <Night>
                    <img src="/images/night-tile.jpg" alt="town" />
                </Night>
            </Link>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    align-content: center;
    background-image: url('/images/background-tile-small.png');
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: center;
    overflow: hidden;
    padding: 40px 0;
    position: relative;

    div {
        text-align: center;
        height: 23vh;
        position: relative;
        transition: transform 1s;
        filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
        &:hover {
            transform: rotate(-6deg);
        }
        img {
            object-fit: cover;
            border: 10px solid white;
            width: 80%;
            max-height: 85%;
            border-radius: 10px;
        }
    }
`;

const Home = styled.span`
    left: 20px;
    position: absolute;
    top: 20px;
    svg {
        fill: white;
        height: 25px;
        opacity: 0.7;
        width: 25px;
    }
    h3 {
        color: white;
        display: inline-block;
        font-size: 28px;
        margin: 0;
        padding-left: 5px;
        text-align: center;
    }
    @media screen and (min-width: 750px) {
        h3 {
            font-size: 32px;
        }
        svg {
            height: 35px;
            width: 35px;
        }
    }
`;

const Town = styled.div`
    top: 35px;
    left: 5px;
    transform: rotate(-4deg);
`;

const Fishing = styled.div`
    transform: rotate(3deg);
    left: -3px;
    top: 16px;
`;

const Beach = styled.div`
    transform: rotate(-1deg);
    top: -5px;
    left: 10px;
`;

const Night = styled.div`
    transform: rotate(2deg);
    left: -5px;
    top: -20px;
`;
