import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function OliviaWithBook(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/olivia-with-book.png" alt="Olivia with book" />
            </Link>
        </>
    );
}

const Image = styled.img`
    bottom: 11%;
    left: 13%;
    position: absolute;
    max-width: 396px;
    width: 252px;
    z-index: 1;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        left: 17%;
        width: 259px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        left: 13%;
        width: 292px;
    }
    @media screen and (min-width: 390px) {
        width: 292px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
    }
`;
