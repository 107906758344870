import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Apple(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/fishing-apple.png" alt="apple" />
            </Link>
        </>
    );
}

const Image = styled.img`
    left: 52%;
    position: absolute;
    top: 9%;
    width: 47px;
    z-index: 2;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        width: 53px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        top: 11%;
        left: 65%;
    }
    @media screen and (min-width: 390px) {
        left: 54%;
        top: 11%;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        left: 46%;
        top: 11%;
        width: 71px;
    }
`;
