import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Arrow from '../components/Arrow';
import ArrowButton from '../components/ArrowButton';

export default function Home(props) {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    // height: calc(var(--vh, 1vh) * 100);
    return (
        <Wrapper>
            <Logo>
                <img src="/images/logo-large.png" alt="Olivia Turns Six!" />
            </Logo>
            <Link to="/instructions">
                <ArrowButton>
                    <Arrow />
                </ArrowButton>
            </Link>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    background-image: url(/images/background-tile-small.png);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
        margin-top: 20px;
    }
`;

const Logo = styled.div`
    width: 85%;
    font-size: 26px;
    text-align: center;
    color: white;
    img {
        max-width: 600px;
        width: 100%;
    }
    @media screen and (min-width: 768px) {
        font-size: 40px;
    }
`;
