import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Scarecrow(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/town-scarecrow.png" alt="scarecrow" />
            </Link>
        </>
    );
}

const Image = styled.img`
    left: 52%;
    position: absolute;
    top: 9%;
    width: 80px;
    z-index: 1;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        width: 85px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        top: 13%;
    }
    @media screen and (min-width: 390px) {
        top: 13%;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        top: 18%;
    }
`;
