import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BottomArrow, Person } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import ArrowButton from '../components/ArrowButton';
import OliviaWithFish from '../components/fishing/OliviaWithFish';

export default function FishingBobber() {
    return (
        <Wrapper>
            <Person>
                <h2>Wow! You caught a fish!</h2>

                <BottomArrow>
                    <Link
                        to={{
                            pathname: '/fishing',
                            state: { showInstructions: false },
                        }}
                    >
                        <ArrowButton>
                            <Arrow />
                        </ArrowButton>
                    </Link>
                </BottomArrow>
            </Person>
            <OliviaWithFish link="fishing-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/fishing-background.jpg);
    background-position: 1% 11%;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
