import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, Quiz } from '../DefaultStyles';
import Textbox from '../components/Textbox';
import Bobber from '../components/fishing/Bobber';
import Apple from '../components/fishing/Apple';
import Olivia from '../components/fishing/Olivia';

export default function FishingOlivia1() {
    return (
        <Wrapper>
            <Quiz shadowColor="#03380b">
                <p>
                    <span>
                        <Link to="fishing-olivia-1a">She's trying to catch a fish!</Link>
                    </span>
                    <span>
                        <Link to="fishing-olivia-1b">
                            She's using it to knock an apple from the tree.
                        </Link>
                    </span>
                    <span>
                        <Link to="fishing-olivia-1c">She's using it to pick some boogers.</Link>
                    </span>
                </p>
            </Quiz>
            <Text opacity="0.5">
                <p>
                    <strong>Olivia</strong> seems to be holding a really long stick. What do you
                    think she is doing?
                </p>
                <Textbox opacity="0.5" />
            </Text>
            <Apple link="fishing-apple" />
            <Bobber link="fishing-bobber" />
            <Olivia link="fishing-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/fishing-background.jpg);
    background-position: 11% 11%;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
