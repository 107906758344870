import React from 'react';
import styled from 'styled-components';

export default function Textbox(props) {
    return (
        <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 306 164">
            <G
                color={props.color}
                opacity={props.opacity}
                transform="matrix(0.322603,0,0,0.322603,-20.1828,-119.03)"
            >
                <path d="M101.056,673.06C76.9,641.233 62.562,601.566 62.562,558.577C62.562,453.929 147.523,368.968 252.171,368.968L820.427,368.968C925.075,368.968 1010.04,453.929 1010.04,558.577C1010.04,602.937 994.769,643.759 969.214,676.064C963.768,686.275 949.437,716.635 947.773,756.039C948.13,759.642 948.312,763.287 948.312,766.975C948.312,827.627 899.07,876.868 838.419,876.868L234.179,876.868C174.377,876.868 125.667,828.996 124.315,769.512C124.279,768.706 124.268,767.859 124.286,766.975C124.286,766.084 124.297,765.194 124.318,764.302C124.537,715.437 104.254,678.567 101.056,673.06Z" />
            </G>
        </svg>
    );
}

const G = styled.g`
    fill: ${(props) => props.color || '#f5eabf'};
    opacity: ${(props) => props.opacity || '1.0'};
`;
