import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Textbox from '../components/Textbox';
import ArrowButton from '../components/ArrowButton';
import Topmenu from '../components/Topmenu';

export default function Counter2() {
    return (
        <Wrapper>
            <Topmenu />
            <Text>
                <span>Tommy</span>
                <p>
                    Time for your first big decision. Please help pick a starting location, and then
                    pack your bags. Woo Hoo!
                </p>

                <Textbox />
                <Link to="/options">
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Text>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background-image: url(images/counter-phone-2.jpg);
    background-size: cover;
    background-position: center;
    display: grid;
`;
