import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BottomArrow, Person } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import ArrowButton from '../components/ArrowButton';
import OliviaWithBook from '../components/beach/OliviaWithBook';

export default function BeachBooks() {
    return (
        <Wrapper>
            <Person>
                <h2>Olivia sure likes to read!</h2>

                <BottomArrow>
                    <Link
                        to={{
                            pathname: '/beach',
                            state: { showInstructions: false },
                        }}
                    >
                        <ArrowButton>
                            <Arrow />
                        </ArrowButton>
                    </Link>
                </BottomArrow>
            </Person>
            <OliviaWithBook link="beach-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/beach-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
