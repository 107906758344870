import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function OliviaWithFish(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/olivia-with-fish.png" alt="Olivia with fish" />
            </Link>
        </>
    );
}

const Image = styled.img`
    bottom: 8%;
    left: 11%;
    position: absolute;
    max-width: 475px;
    width: 283px;
    z-index: 1;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        width: 310px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
    }
    @media screen and (min-width: 390px) {
        width: 350px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        bottom: 14%;
        left: 15%;
        width: 447px;
    }
`;
