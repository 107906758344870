import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
function setDocHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
}

window.addEventListener('resize', function () {
    setDocHeight();
});
window.addEventListener('orientationchange', function () {
    setDocHeight();
});

setDocHeight();

export const GlobalStyle = createGlobalStyle`



* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
    /* font-style: normal;
    cursor: url('/images/glove-cursor.png'), pointer; */
}

body {
  margin: 0;
  background-color: black;
}

:root {
    --light-purple: gold;
    --fc1: #f20;
    --fc2: #ff0;
    --fc3: #04f;
    --fc4: #0f4;
    --fc5: #f0f;
    --fc: var(--fc1);

    --fc6: #d0ff00;
    --fc7: #ff0;
    --fc8: #04f;
    --fc9: #0f4;
    --fc10: #f0f;
    --fc-2: var(--fc6);

    --fc11: #00b7ff;
    --fc12: #ff0;
    --fc13: #04f;
    --fc14: #0f4;
    --fc15: #f0f;
    --fc-3: var(--fc11);
}
`;

export const Text = styled.div`
    bottom: 50px;
    height: 250px;
    padding: 0 20px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 3;
    span {
        background-color: ${(props) => props.color || '#d99218'};
        border-radius: 15px;
        color: #844006;
        display: inline-block;
        font-size: 18px;
        left: 25px;
        padding: 3px 19px;
        position: absolute;
        top: -14px;
        transform: rotate(-11deg);
        z-index: 2;
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        height: 250px;
    }
    @media screen and (min-width: 349px) {
        height: 230px;
    }
    @media screen and (min-width: 390px) {
        height: 195px;
    }
    @media screen and (min-width: 428px) {
        height: 163px;
    }
    @media screen and (min-width: 750px) {
        height: 260px;
        span {
            font-size: 25px;
        }
    }
    p {
        color: #5e573e;
        font-size: ${(props) => props.inputSize || '22px'};
        height: 100%;
        left: 20px;
        opacity: ${(props) => props.opacity || '1.0'};
        padding: 20px 40px;
        position: absolute;
        right: 20px;
        text-align: center;
        z-index: 1;
        strong {
            color: #1e8cd6;
        }
        @media screen and (min-width: 750px) {
            font-size: 34px;
            padding: 20px 65px;
        }
    }
`;

export const Quiz = styled.div`
    bottom: 150px;
    height: 210px;
    padding: 0 20px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 4;
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        height: 250px;
    }
    @media screen and (min-width: 349px) {
        height: 230px;
    }
    @media screen and (min-width: 390px) {
        height: 195px;
    }
    @media screen and (min-width: 428px) {
        height: 163px;
    }
    @media screen and (min-width: 750px) {
        height: 260px;
    }
    p {
        color: #5e573e;
        font-size: ${(props) => props.inputSize || '16px'};
        height: 100%;
        left: 20px;
        line-height: 20px;
        padding: 40px 26px 15px 25px;
        position: absolute;
        right: 20px;
        text-align: left;
        z-index: 1;
        strong {
            color: #1e8cd6;
        }
        span {
            display: block;
            background-color: #f5f2a9;
            border-radius: 10px;
            filter: drop-shadow(2px 2px 2px ${(props) => props.shadowColor || '#222222'});
            margin-top: 20px;
            position: relative;
            width: fit-content;
            padding: 10px;
            &:first-of-type {
                margin-top: 0;
            }
            a {
                color: #5e573e;
                text-decoration: none;
            }
            &:hover {
                background-color: gold;
            }
            &:active {
                background-color: gold;
            }
        }
    }
    svg {
        filter: drop-shadow(2px 2px 2px #664317);
    }
`;

export const Person = styled.div`
    bottom: 30px;
    height: 445px;
    padding: 0 20px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 3;
    h2 {
        font-size: 40px;
        line-height: 41px;
        color: white;
        filter: drop-shadow(2px 2px 4px #664317);
    }
    img {
        display: block;
        margin: 0 auto;
        max-width: 570px;
        width: 100%;
    }

    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        height: 480px;
        padding: 0 35px;
    }
    @media screen and (min-width: 349px) {
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        height: 550px;
    }
    @media screen and (min-width: 390px) {
        height: 540px;
        padding: 0 45px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        height: 790px;
        h2 {
            font-size: 70px;
            line-height: 71px;
            width: 500px;
            margin: 0 auto;
        }
    }
`;

export const Reaction = styled.div`
    bottom: 30px;
    height: 355px;
    padding: 0 20px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 3;
    h2 {
        font-size: 40px;
        color: white;
        filter: drop-shadow(2px 2px 4px #664317);
    }
    img {
        display: block;
        margin: 0 auto;
        max-width: 570px;
        width: 100%;
    }

    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        height: 390px;
    }
    @media screen and (min-width: 349px) {
        height: 410px;
    }
    @media screen and (min-width: 390px) {
        height: 450px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        height: 700px;
        h2 {
            font-size: 70px;
        }
    }
`;

export const BottomArrow = styled.div`
    bottom: -35px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 3;
`;

export const Strong = styled.span`
    font-size: inherit;
    font-weight: bold;
`;
