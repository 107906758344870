import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Invitation from './Invitation';
import Thanks from './Thanks';
import Home from './Home';
import Instructions from './Instructions';
import Counter from './Counter';
import Counter2 from './Counter2';
import Options from './Options';
import Town from './Town';
import TownScarecrow from './TownScarecrow';
import TownBucket from './TownBucket';
import TownBucket1 from './TownBucket1';
import TownBucket1a from './TownBucket1a';
import TownBucket1b from './TownBucket1b';
import TownBucket1c from './TownBucket1c';
import TownOlivia from './TownOlivia';
import Fishing from './Fishing';
import FishingBobber from './FishingBobber';
import FishingApple from './FishingApple';
import FishingOlivia from './FishingOlivia';
import FishingOlivia1 from './FishingOlivia1';
import FishingOlivia1a from './FishingOlivia1a';
import FishingOlivia1b from './FishingOlivia1b';
import FishingOlivia1c from './FishingOlivia1c';
import Beach from './Beach';
import BeachOlivia from './BeachOlivia';
import BeachBall from './BeachBall';
import BeachBooks from './BeachBooks';
import BeachBall1 from './BeachBall1';
import BeachBall1a from './BeachBall1a';
import BeachBall1b from './BeachBall1b';
import BeachBall1c from './BeachBall1c';
import Night from './Night';
import NightStar from './NightStar';
import NightOlivia from './NightOlivia';
import NightMoon from './NightMoon';
import NightMoon1 from './NightMoon1';
import NightMoon1a from './NightMoon1a';
import NightMoon1b from './NightMoon1b';
import NightMoon1c from './NightMoon1c';
import Zoom from './Zoom';
import { SoundContext } from '../GlobalContext';

export default function Routes() {
    // const sound = window.localStorage.getItem('sound');
    const [soundOn] = useContext(SoundContext);

    // useEffect(() => {
    //     window.localStorage.setItem('sound', soundOn);
    // }, [soundOn]);

    // const sound = window.localStorage.getItem('sound');

    setTimeout(() => {
        const x = document.getElementById('myAudio');
        x.play();
        if (soundOn) {
            x.volume = 0.4;
        } else {
            x.volume = 0.0;
        }
    }, 1000);
    return (
        <>
            <audio id="myAudio" loop allow="autoplay">
                <source
                    src="https://res.cloudinary.com/billpliske/video/upload/v1596923352/grandkids/animal-crossing-theme.mp3"
                    type="audio/mpeg"
                />
                <track kind="captions" srcLang="en" label="english_captions"></track>
                Your browser does not support the audio element.
            </audio>

            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/instructions" component={Instructions} />
                <Route exact path="/counter" component={Counter} />
                <Route exact path="/counter2" component={Counter2} />
                <Route exact path="/options" component={Options} />
                <Route exact path="/town" component={Town} />
                <Route exact path="/town-scarecrow" component={TownScarecrow} />
                <Route exact path="/town-bucket" component={TownBucket} />
                <Route exact path="/town-bucket-1" component={TownBucket1} />
                <Route exact path="/town-bucket-1a" component={TownBucket1a} />
                <Route exact path="/town-bucket-1b" component={TownBucket1b} />
                <Route exact path="/town-bucket-1c" component={TownBucket1c} />
                <Route exact path="/town-olivia" component={TownOlivia} />
                <Route exact path="/fishing" component={Fishing} />
                <Route exact path="/fishing-bobber" component={FishingBobber} />
                <Route exact path="/fishing-apple" component={FishingApple} />
                <Route exact path="/fishing-olivia" component={FishingOlivia} />
                <Route exact path="/fishing-olivia-1" component={FishingOlivia1} />
                <Route exact path="/fishing-olivia-1a" component={FishingOlivia1a} />
                <Route exact path="/fishing-olivia-1b" component={FishingOlivia1b} />
                <Route exact path="/fishing-olivia-1c" component={FishingOlivia1c} />
                <Route exact path="/beach" component={Beach} />
                <Route exact path="/beach-ball" component={BeachBall} />
                <Route exact path="/beach-olivia" component={BeachOlivia} />
                <Route exact path="/beach-books" component={BeachBooks} />
                <Route exact path="/beach-ball-1" component={BeachBall1} />
                <Route exact path="/beach-ball-1a" component={BeachBall1a} />
                <Route exact path="/beach-ball-1b" component={BeachBall1b} />
                <Route exact path="/beach-ball-1c" component={BeachBall1c} />
                <Route exact path="/night" component={Night} />
                <Route exact path="/night-star" component={NightStar} />
                <Route exact path="/night-olivia" component={NightOlivia} />
                <Route exact path="/night-moon" component={NightMoon} />
                <Route exact path="/night-moon-1" component={NightMoon1} />
                <Route exact path="/night-moon-1a" component={NightMoon1a} />
                <Route exact path="/night-moon-1b" component={NightMoon1b} />
                <Route exact path="/night-moon-1c" component={NightMoon1c} />
                <Route exact path="/invitation" component={Invitation} />
                <Route exact path="/zoom" component={Zoom} />
                <Route exact path="/thanks" component={Thanks} />
            </Switch>
        </>
    );
}
