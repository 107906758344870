import React, { useState } from 'react';

export const SoundContext = React.createContext(false);

const GlobalContext = ({ children }) => {
    const [soundOn, setSoundOn] = useState(false);
    let sound = document.getElementById('myAudio');

    console.log('====================================');
    console.log('my soundOn: ' + sound);
    console.log('====================================');

    return <SoundContext.Provider value={[soundOn, setSoundOn]}>{children}</SoundContext.Provider>;
};

export default GlobalContext;
