import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Olivia(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/fishing-olivia-right.png" alt="Olivia" />
            </Link>
        </>
    );
}

const Image = styled.img`
    bottom: 33%;
    left: 6%;
    position: absolute;
    max-width: 312px;
    width: 228px;
    z-index: 1;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        left: 7%;
        width: 230px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        left: 9%;
        width: 270px;
    }
    @media screen and (min-width: 390px) {
        left: 10%;
        width: 250px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        left: 13%;
        width: 302px;
    }
`;
