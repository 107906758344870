import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TiHome } from 'react-icons/ti';
import { MdCake } from 'react-icons/md';
import { GiSoundOff, GiSoundOn } from 'react-icons/gi';
import { SoundContext } from '../GlobalContext';

export default function Topmenu() {
    const [soundOn, setSoundOn] = useContext(SoundContext);

    return (
        <Wrapper id="topmenu">
            <Link to="/options">
                <TiHome />
            </Link>
            <Link to="/zoom">
                <MdCake />
            </Link>
            <div>
                {soundOn ? (
                    <GiSoundOn onClick={() => setSoundOn(false)} />
                ) : (
                    <GiSoundOff onClick={() => setSoundOn(true)} />
                )}
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 20px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 5;
    svg {
        filter: drop-shadow(1px 1px 4px #333333);
        fill: white;
        height: 25px;
        opacity: 0.7;
        width: 25px;
    }
`;
