import React, { useContext } from 'react';
import styled from 'styled-components';
// import local
import { GlobalStyle } from './DefaultStyles';
import Routing from './pages/Routes';
import { SoundContext } from './GlobalContext';

export default function App() {
    const [soundOn, setSoundOn] = useContext(SoundContext);

    console.log('global: ' + soundOn);

    const handleIntro = () => {
        const bk = document.getElementById('bb');
        bk.style.display = 'none';
        bk.style.width = '0';
        bk.style.height = '0';
        setSoundOn(true);

        let sound = document.getElementById('myAudio');
        sound.volume = 0;
        sound.play();

        setTimeout(() => {
            let sound = document.getElementById('myAudio');
            sound.volume = 0.4;
            sound.play();
        }, 200);
    };

    return (
        <Wrapper>
            <audio id="myAudio" loop allow="autoplay">
                <source
                    src="https://res.cloudinary.com/billpliske/video/upload/v1596923352/grandkids/animal-crossing-theme.mp3"
                    type="audio/mpeg"
                />
                <track kind="captions" srcLang="en" label="english_captions"></track>
                Your browser does not support the audio element.
            </audio>
            <GlobalStyle />
            <Screen id="bb">
                <button id="audiobutton" onClick={() => handleIntro()}>
                    Enter
                </button>
            </Screen>
            <Routing />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    position: relative;
`;

const Screen = styled.span`
    position: absolute;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-image: url(/images/background-tile-small.png);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 9;

    button {
        background: #16631a;
        border: white 1px solid;
        border-radius: 40px;
        padding: 10px 30px;
        color: white;
        font-size: 25px;
        filter: drop-shadow(2px 2px 4px #000000);
    }
`;
