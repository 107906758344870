import React from 'react';
import styled from 'styled-components';
import Bobber from '../components/fishing/Bobber';
import Apple from '../components/fishing/Apple';
import Olivia from '../components/fishing/Olivia';
import Topmenu from '../components/Topmenu';
import Clickfind from '../components/Clickfind';
import { useLocation } from 'react-router-dom';

export default function Fishing() {
    let data = useLocation();
    return (
        <Wrapper>
            <Topmenu />
            {data.state ? <></> : <Clickfind />}
            <Apple link="fishing-apple" />
            <Bobber link="fishing-bobber" />
            <Olivia link="fishing-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/fishing-background.jpg);
    background-position: 11% 11%;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
