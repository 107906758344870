import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Olivia(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/town-olivia.png" alt="Olivia" />
            </Link>
        </>
    );
}

const Image = styled.img`
    bottom: 3%;
    left: 10%;
    position: absolute;
    width: 275px;
    z-index: 1;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        bottom: 0%;
        left: 9%;
        width: 333px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        bottom: 3%;
        left: 7%;
        width: 356px;
    }
    @media screen and (min-width: 390px) {
        bottom: 3%;
        left: 7%;
        width: 356px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        bottom: 15%;
        left: 9%;
    }
`;
