import React from 'react';
import styled from 'styled-components';
import Star from '../components/night/Star';
import Moon from '../components/night/Moon';
import Olivia from '../components/night/Olivia';
import Topmenu from '../components/Topmenu';
import Clickfind from '../components/Clickfind';
import { useLocation } from 'react-router-dom';

export default function Night() {
    let data = useLocation();
    setTimeout(() => {
        const x = document.getElementById('crickets');
        x.volume = 0.2;
        x.play();
    }, 400);
    return (
        <Wrapper>
            <Topmenu />
            <audio id="crickets" loop allow="autoplay">
                <source
                    src="https://res.cloudinary.com/billpliske/video/upload/v1597005498/grandkids/crickets.mp3"
                    type="audio/mpeg"
                />
                <track kind="captions" srcLang="en" label="english_captions"></track>
                Your browser does not support the audio element.
            </audio>
            {data.state ? <></> : <Clickfind />}
            <Moon link="night-moon" />
            <Star link="night-star" />
            <Olivia link="night-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/night-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
