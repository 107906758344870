import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Moon from '../components/night/Moon';
import Olivia from '../components/night/Olivia';
import ArrowButton from '../components/ArrowButton';

export default function NightOlivia() {
    setTimeout(() => {
        const x = document.getElementById('myMoon');
        x.volume = 0.8;
        x.play();
    }, 10);
    return (
        <Wrapper>
            <audio id="myMoon" loop allow="autoplay">
                <source
                    src="https://res.cloudinary.com/billpliske/video/upload/v1597004897/grandkids/Fireworks.mp3"
                    type="audio/mpeg"
                />
                <track kind="captions" srcLang="en" label="english_captions"></track>
                Your browser does not support the audio element.
            </audio>
            <div className="fireworks" />
            <div className="fireworks2" />
            <div className="fireworks3" />
            <Text style={{ height: '0' }}>
                <Link
                    to={{
                        pathname: '/night',
                        state: { showInstructions: false },
                    }}
                >
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Text>
            <Moon link="night-moon" />
            <Olivia link="night-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/night-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
