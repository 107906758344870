import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Textbox from '../components/Textbox';
import ArrowButton from '../components/ArrowButton';
import Topmenu from '../components/Topmenu';

export default function Instructions() {
    return (
        <Wrapper>
            <InstructionsWrapper>
                <h2>
                    This menu (above) has buttons that take you to the <strong>homepage</strong>,
                    the <strong>birthday invitation</strong>, or mutes the{' '}
                    <strong>background music</strong>.
                </h2>
                <Title>
                    <h1>Got it?</h1>
                    <Link to="/counter">Continue</Link>
                </Title>
                <h3>
                    When you click on this bouncing arrow (below), you'll go to the next message, or
                    next screen.
                </h3>
            </InstructionsWrapper>
            <Topmenu />
            <Text>
                <span>Timmy</span>
                <p>
                    Welcome, visitor. We're Timmy and Tommy. Let us be the first to congratulate you
                    on your trip to Jade Island!
                </p>

                <Textbox />
                <Link to="/counter2">
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Text>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(images/counter-phone.jpg);
    background-position: center;
    background-size: cover;
    display: grid;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    width: 100%;
`;

const InstructionsWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.7);
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    width: 100%;
    z-index: 6;
    h2 {
        background-color: white;
        filter: drop-shadow(2px 2px 2px #999999);
        font-size: 16px;
        padding: 18px 23px;
        position: absolute;
        top: 60px;
        width: 77%;
        strong {
            color: #18359e;
        }
    }
    h3 {
        background-color: white;
        filter: drop-shadow(2px 2px 2px #999999);
        font-size: 16px;
        padding: 18px 23px;
        position: absolute;
        bottom: 75px;
        width: 77%;
        strong {
            color: #18359e;
        }
    }
    @media screen and (min-width: 750px) {
        h2,
        h3 {
            font-size: 22px;
        }
    }
`;

const Title = styled.div`
    h1 {
        color: black;
        font-size: 50px;
        line-height: 50px;
        padding-top: 20px;
        @media screen and (min-width: 321px) {
            padding-top: 0;
        }
        @media screen and (min-width: 375px) {
            padding-bottom: 10px;
        }
        @media screen and (min-width: 390px) {
        }
        @media screen and (min-width: 428px) {
        }
        @media screen and (min-width: 750px) {
            font-size: 82px;
            padding-bottom: 25px;
        }
    }
    a {
        background-color: black;
        border-radius: 25px;
        color: white;
        display: block;
        margin: 0;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
        @media screen and (min-width: 321px) {
            padding: 12px 15px;
        }
        @media screen and (min-width: 750px) {
            padding: 18px 15px;
            font-size: 20px;
        }
    }
`;
