import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Bucket(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/town-bucket.png" alt="bucket" />
            </Link>
        </>
    );
}

const Image = styled.img`
    bottom: 34%;
    position: absolute;
    right: -9%;
    width: 120px;
    z-index: 2;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        right: -7%;
        width: 125px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        width: 150px;
    }
    @media screen and (min-width: 390px) {
        width: 150px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        right: -5%;
        width: 212px;
    }
`;
