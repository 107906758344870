import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Books(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/beach-books.png" alt="bucket" />
            </Link>
        </>
    );
}

const Image = styled.img`
    bottom: 26%;
    filter: drop-shadow(2px 2px 6px #664317);
    position: absolute;
    right: 16%;
    width: 66px;
    z-index: 2;

    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        width: 73px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        bottom: 32%;
    }
    @media screen and (min-width: 390px) {
        bottom: 29%;
        right: 19%;
        width: 76px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        bottom: 31%;
        right: 30%;
        width: 83px;
    }
`;
