import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Reaction } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Bobber from '../components/fishing/Bobber';
import Apple from '../components/fishing/Apple';
import Olivia from '../components/fishing/Olivia';
import ArrowButton from '../components/ArrowButton';

export default function FishingOlivia1b() {
    return (
        <Wrapper>
            <Reaction>
                <h2>Oh, really?</h2>

                <img src="/images/isabelle.png" alt="Isabelle" />
                <Link
                    to={{
                        pathname: '/fishing',
                        state: { showInstructions: false },
                    }}
                >
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Reaction>
            <Apple link="fishing-apple" />
            <Bobber link="fishing-bobber" />
            <Olivia link="fishing-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/fishing-background.jpg);
    background-position: 11% 11%;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
