import React from 'react';
import styled, { keyframes } from 'styled-components';

export default function Clickfind() {
    setTimeout(() => {
        const el = document.getElementById('clickfind');
        if (el) {
            el.style.WebkitTransition = 'opacity 1s ease-in-out;';
            el.style.opacity = '0';
        }
    }, 1200);
    setTimeout(() => {
        const el = document.getElementById('clickfind');
        if (el) {
            el.style.display = 'none';
        }
    }, 2200);
    return (
        <Wrapper id="clickfind">
            <h2>Click and explore</h2>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: absolute;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    animation: fadein 2s;
    animation-delay: 2s;
    animation-duration: 2s;
    width: 100%;
    z-index: 3;
    h2 {
        color: white;
        filter: drop-shadow(2px 2px 2px ${(props) => props.shadowColor || '#222222'});
        font-size: 40px;
        line-height: 42px;
        width: 65%;
        @media screen and (min-width: 321px) {
        }
        @media screen and (min-width: 375px) and (min-height: 811px) {
        }
        @media screen and (min-width: 390px) {
        }
        @media screen and (min-width: 428px) {
        }
        @media screen and (min-width: 750px) {
            font-size: 40px;
        }
    }
`;

export const fadein = keyframes`
    0% {opacity: 1;}
  100% {opacity: 0;}
`;
