import React from 'react';
import styled, { keyframes } from 'styled-components';
import Arrow from './Arrow';

export default function ArrowButton(props) {
    const handleClick = (e) => {
        e.stopPropagation();
        const x = document.getElementById('button');
        x.volume = 0.3;
        x.play();
    };

    console.log('home: ' + props.home);
    return (
        <Button onMouseDown={handleClick} onTouchStart={handleClick}>
            <audio id="button" allow="autoplay">
                <source
                    src="https://res.cloudinary.com/billpliske/video/upload/v1597005498/grandkids/button-click.mp3"
                    type="audio/mpeg"
                />
                <track kind="captions" srcLang="en" label="english_captions"></track>
                Your browser does not support the audio element.
            </audio>
            <Arrow />
        </Button>
    );
}

export const bounce = keyframes`
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-5px);
    }
`;

const Button = styled.button`
    animation: ${bounce} 2s infinite;
    position: relative;
    background-color: transparent;
    border: none;
    height: 30px;
    margin: -13px auto 30px auto;
    outline: none;
    width: 40px;
    z-index: 5;
    svg {
        fill: gold;
        filter: drop-shadow(2px 2px 2px #664317);
    }
    &:hover {
        cursor: url('/images/glove-cursor.png'), pointer;
        svg {
            fill: orange;
        }
    }
`;
