import React from 'react';
import styled from 'styled-components';
import Scarecrow from '../components/town/Scarecrow';
import Bucket from '../components/town/Bucket';
import Olivia from '../components/town/Olivia';
import Topmenu from '../components/Topmenu';
import Clickfind from '../components/Clickfind';
import { useLocation } from 'react-router-dom';

export default function Town(props) {
    let data = useLocation();
    console.log('====================================');
    console.log(data);
    console.log('====================================');
    return (
        <Wrapper>
            <Topmenu />
            {data.state ? <></> : <Clickfind />}
            <Scarecrow link="town-scarecrow" />
            <Bucket link="town-bucket" />
            <Olivia link="town-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/town-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
