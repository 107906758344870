import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Textbox from '../components/Textbox';
import Star from '../components/night/Star';
import Moon from '../components/night/Moon';
import Olivia from '../components/night/Olivia';
import ArrowButton from '../components/ArrowButton';

export default function NightStar() {
    return (
        <Wrapper>
            <Text>
                <p>
                    Did you know shooting stars are extremely fast? They can reach speeds of over
                    120,000 miles per hour! Wow!
                </p>

                <Textbox />
                <Link
                    to={{
                        pathname: '/night',
                        state: { showInstructions: false },
                    }}
                >
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Text>
            <Moon link="night-moon" />
            <Star link="night-star" />
            <Olivia link="night-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/night-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
