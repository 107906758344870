import React from 'react';
import styled from 'styled-components';
import Topmenu from '../components/Topmenu';

export default function Zoom(props) {
    return (
        <Wrapper>
            <Topmenu />

            <Info style={{ paddingTop: '75px' }}>
                <img src="/images/zoom.png" alt="zoom" />
                <ul>
                    <Date>
                        <strong>Topic</strong> <span>Olivia Turns Six!</span>
                    </Date>
                    <Date>
                        <strong>Time</strong> <span>Sept. 5, 2020 06:00 PM Arizona</span>
                    </Date>
                </ul>
            </Info>
            <Wishlist2>
                <a
                    href="https://us04web.zoom.us/j/6244029732?pwd=Qk5IVW54RWZEZTZjcm1na1RXRmlwQT09"
                    target="new"
                >
                    <h3>Join Zoom Meeting</h3>
                </a>
            </Wishlist2>
            <Info>
                <ul>
                    <Date>
                        <strong>Meeting ID</strong> <span>624 402 9732</span>
                    </Date>
                    <Date>
                        <strong>Passcode</strong> <span>olivia</span>
                    </Date>
                </ul>
            </Info>
            <p>
                Due to Covid, we will be hosting a birthday zoom meeting for Olivia's 6th birthday.
                This will include singing happy birthday and the opening of presents. Hope to
                virtually see you there!
            </p>
            <Wishlist>
                <a
                    href="https://www.amazon.com/hz/wishlist/ls/3VE3XEHJJO9GM?ref_=wl_share"
                    target="new"
                >
                    <h3>Olivia's Amazon wishlist</h3>
                </a>
            </Wishlist>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-color: #b9f1fe;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    p {
        padding: 10px 25px;
        font-size: 14px;
    }
    @media screen and (min-width: 760px) {
        p {
            font-size: 18px;
            padding-left: 50px;
            width: 80%;
        }
    }
`;

const Info = styled.div`
    color: #222;
    margin: 0px auto 15px auto;
    padding: 10px 30px 0 20px;
    img {
        max-width: 200px;
        width: 50%;
        margin-bottom: 5px;
    }
    ul {
        list-style-type: none;
    }

    strong {
        background-color: #fd7926;
        border-radius: 30px;
        color: black;
        display: inline-block;
        padding: 3px 5px;
        text-align: center;
        width: 103px;
    }
    @media screen and (min-width: 375px) {
        margin-bottom: 25px;
    }
    @media screen and (min-height: 800px) {
        margin: 0px auto 35px auto;
    }
    @media screen and (min-width: 760px) {
        padding: 30px 50px 0 50px;
        img {
            margin-top: 40px;
        }
    }
`;

const Date = styled.li`
    margin-bottom: 10px;
    display: block;

    span {
        display: inline-block;
        margin-left: 15px;
        vertical-align: top;
        width: 145px;
    }
`;

const Wishlist = styled.div`
    background-color: white;
    border-radius: 20px;
    border: 1px solid #fd7926;
    margin-left: 20px;
    padding: 7px 18px;
    width: fit-content;
    a {
        text-decoration: none;
        color: #222;
    }
    @media screen and (min-width: 760px) {
        margin: 30px 50px 0 50px;
    }
`;

const Wishlist2 = styled.div`
    background-color: #0a88d1;
    border-radius: 20px;
    margin-left: 20px;
    padding: 7px 18px 3px 18px;
    width: fit-content;
    a {
        text-decoration: none;
        color: #fff;
    }
    @media screen and (min-width: 760px) {
        margin: 10px 50px 0 50px;
    }
`;
