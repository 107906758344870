import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Textbox from '../components/Textbox';
import ArrowButton from '../components/ArrowButton';
import Topmenu from '../components/Topmenu';

export default function Counter() {
    return (
        <Wrapper>
            <Topmenu />
            <Text>
                <span>Timmy</span>
                <p>
                    Welcome, visitor. We're Timmy and Tommy. Let us be the first to congratulate you
                    on your trip to Jade Island!
                </p>

                <Textbox />
                <Link to="/counter2">
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Text>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(images/counter-phone.jpg);
    background-position: center;
    background-size: cover;
    display: grid;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    width: 100%;
`;
