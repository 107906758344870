import React from 'react';
import styled from 'styled-components';

export default function Thanks() {
    return (
        <Wrapper>
            <p> Thanks</p>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-color: rgb(208, 20, 34);
    color: white;
    height: 100vh;
    width: 100%;
    img {
        bottom: -3px;
        text-align: center;
        position: fixed;
    }
`;
