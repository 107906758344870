import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, Quiz } from '../DefaultStyles';
import Textbox from '../components/Textbox';
import Scarecrow from '../components/town/Scarecrow';
import Bucket from '../components/town/Bucket';
import Olivia from '../components/town/Olivia';

export default function TownBucket1() {
    return (
        <Wrapper>
            <Quiz shadowColor="#593207">
                <p>
                    <span>
                        <Link to="town-bucket-1a">My plants need watering.</Link>
                    </span>
                    <span>
                        <Link to="town-bucket-1b">Shh. It's a secret science experiment!</Link>
                    </span>
                    <span>
                        <Link to="town-bucket-1c">I need to clean my armpits.</Link>
                    </span>
                </p>
            </Quiz>
            <Text opacity="0.5">
                <p>
                    Hey, you just picked up that wooden bucket! We're really curious, why do you
                    need a bucket?
                </p>
                <Textbox opacity="0.5" />
            </Text>
            <Scarecrow link="town-scarecrow" />
            <Bucket link="town-bucket" />
            <Olivia link="town-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/town-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
