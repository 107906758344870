import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Star(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/night-star.png" alt="star" />
            </Link>
        </>
    );
}

const Image = styled.img`
    left: 26%;
    position: absolute;
    top: 8%;
    width: 158px;
    z-index: 2;

    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        left: 27%;
        top: 6%;
        width: 178px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
    }
    @media screen and (min-width: 390px) {
        width: 195px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        width: 235px;
    }
`;
