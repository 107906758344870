import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, Quiz } from '../DefaultStyles';
import Textbox from '../components/Textbox';
import Ball from '../components/beach/Ball';
import Books from '../components/beach/Books';
import Olivia from '../components/beach/Olivia';

export default function BeachBall1() {
    return (
        <Wrapper>
            <Quiz shadowColor="#453005">
                <p>
                    <span>
                        <Link to="beach-ball-1a">I'm going to bounce it!</Link>
                    </span>
                    <span>
                        <Link to="beach-ball-1b">We're going to play a game of kickball!</Link>
                    </span>
                    <span>
                        <Link to="beach-ball-1c">
                            I'm going to bounce it off your bottom, dude.
                        </Link>
                    </span>
                </p>
            </Quiz>
            <Text opacity="0.5">
                <p>
                    <strong>Olivia</strong> seems to be holding a really long stick. What do you
                    think she is doing?
                </p>
                <Textbox opacity="0.5" />
            </Text>
            <Books link="beach-books" />
            <Ball link="beach-ball" />
            <Olivia link="beach-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/beach-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
