import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export default function Bobber(props) {
    return (
        <>
            <Link to={'/' + props.link}>
                <Image src="/images/fishing-bobber.png" alt="bucket" />
            </Link>
        </>
    );
}

export const bounce = keyframes`
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-39px);
        transform: translateY(-3px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-2px);
    }
`;

const Image = styled.img`
    bottom: 17%;
    position: absolute;
    right: -10%;
    animation: ${bounce} 3s infinite;
    width: 148px;
    z-index: 2;

    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
    /* adjust textbox height per device */
    @media screen and (min-width: 321px) {
        right: -7%;
        width: 153px;
    }
    @media screen and (min-width: 375px) and (min-height: 811px) {
        right: -7%;
        width: 153px;
        bottom: 24%;
    }
    @media screen and (min-width: 390px) {
        right: -6%;
        width: 150px;
    }
    @media screen and (min-width: 428px) {
    }
    @media screen and (min-width: 750px) {
        bottom: 17%;
        right: 7%;
        width: 169px;
    }
`;
