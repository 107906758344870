import React from 'react';
import styled from 'styled-components';
import Ball from '../components/beach/Ball';
import Books from '../components/beach/Books';
import Olivia from '../components/beach/Olivia';
import Topmenu from '../components/Topmenu';
import Clickfind from '../components/Clickfind';
import { useLocation } from 'react-router-dom';

export default function Beach() {
    let data = useLocation();
    return (
        <Wrapper>
            <Topmenu />
            {data.state ? <></> : <Clickfind />}
            <Books link="beach-books" />
            <Ball link="beach-ball" />
            <Olivia link="beach-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/beach-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
