import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../DefaultStyles';
import Arrow from '../components/Arrow';
import Textbox from '../components/Textbox';
import Scarecrow from '../components/town/Scarecrow';
import Bucket from '../components/town/Bucket';
import Olivia from '../components/town/Olivia';
import ArrowButton from '../components/ArrowButton';

export default function TownOlivia() {
    return (
        <Wrapper>
            <Text>
                <p>
                    This is Olivia, have you met her? She's the bestest bug catcher in all of Jade
                    Island! Bzzzzzzz - Gotcha!
                </p>

                <Textbox />
                <Link
                    to={{
                        pathname: '/town',
                        state: { showInstructions: false },
                    }}
                >
                    <ArrowButton>
                        <Arrow />
                    </ArrowButton>
                </Link>
            </Text>
            <Scarecrow link="town-scarecrow" />
            <Bucket link="town-bucket" />
            <Olivia link="town-olivia" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url(/images/town-background.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    &:hover {
        filter: drop-shadow(0px 0px 10px #fffcf5);
    }
`;
