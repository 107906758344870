import React from 'react';

export default function Arrow() {
    return (
        <>
            <svg width="100%" height="100%" viewBox="0 0 68 42">
                <g transform="matrix(1,0,0,1,-917.744,-549.417)">
                    <path d="M931,574L944,586C944,586 951.578,596.828 963,584L982,564C982,564 989.328,551.328 979,551L969,551C969,551 960.828,553.828 936,551L931,550C931,550 914.578,548.328 919,560C919,560 920.328,563.578 925,568L931,574Z" />
                </g>
            </svg>
        </>
    );
}
